<template>
  <div>
    <h3 class="text-muted mb-5">
      {{
        `${$t("page.event.eventTitle")}: ${post.title} - ${
          post.eventId.date
        } - ${post.eventId.time}`
      }}
    </h3>
    <b-row no-gutters class="align-items-center">
      <event-participant-filters
        style="flex-grow: 1"
        :eventId="post.eventId._id"
      />
      <b-button variant="secondary" class="ml-auto" @click="exportData">{{
        $t("page.event.export")
      }}</b-button>
    </b-row>
    <infinite-scroll @load="retrieveParticipants">
      <participants-table
        :participants="formatedParticipants"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        no-local-sorting
        @toggleRegistration="toggleRegisterParticipant"
      />
    </infinite-scroll>
  </div>
</template>

<script>
import ParticipantsTable from "../components/Event/EventParticipantsTable.vue";
import InfiniteScroll from "../components/Base/BaseInfiniteScroll.vue";
import userMixin from "./../mixins/userMixin";
import EventParticipantFilters from "../components/Event/EventParticipantFilters.vue";
import xlsx from "json-as-xlsx";
export default {
  mixins: [userMixin],
  components: {
    ParticipantsTable,
    InfiniteScroll,
    EventParticipantFilters
  },
  data() {
    return {
      fields: [
        {
          key: "participatedAt",
          label: "page.event.participatedDate",
          sort: false
        },
        { key: "fullname", label: "nameAndSurname", sort: false },
        { key: "email", label: "email", sort: false },
        { key: "registered", label: "page.event.registration", sort: false }
      ]
    };
  },
  async created() {
    await this.retrievePost();
    await this.retrieveParticipants(true);
  },
  computed: {
    post() {
      return this.$store.getters["post/getCurrentPost"] || {};
    },
    participants() {
      return this.$store.getters["event/getParticipants"];
    },
    formatedParticipants() {
      return this.participants.map(item => ({
        id: item._id,
        userId: item.userId._id,
        fullname: `${item.userId.profile.name} ${item.userId.profile.surname}`,
        name: item.userId.profile.name,
        surname: item.userId.profile.surname,
        registered: item.registered === undefined ? false : item.registered,
        email: item.userId.email,
        condominiumId: item.userId.condominiums[0]?.condominiumId,
        photo: this.$_getUserProfilePhoto(item.userId.profile),
        participatedAt: item.participatedAt
      }));
    },
    sortBy: {
      get() {
        return this.$store.getters["event/getSortBy"];
      },
      set(value) {
        this.$store.commit("event/SET_SORT_BY", value);
        this.retrieveEvents(true);
      }
    },
    sortDesc: {
      get() {
        return this.$store.getters["event/getSortDesc"];
      },
      set(value) {
        this.$store.commit("event/SET_SORT_DESC", value);
        this.retrieveEvents(true);
      }
    }
  },
  methods: {
    async retrieveParticipants(isInitial) {
      try {
        await this.$store.dispatch("event/retrieveParticipants", {
          eventId: this.post.eventId._id,
          isInitial
        });
      } catch (error) {
        console.error(error);
      }
    },
    async retrievePost() {
      try {
        await this.$store.dispatch("post/retrievePost", {
          postId: this.$route.params.postId,
          condominiumId: this.$route.params.condominiumId
        });
      } catch (error) {
        console.error(error);
      }
    },
    async toggleRegisterParticipant({ userId, value }) {
      try {
        if (this.$store.getters["auth/isDomiUser"]) return;
        const params = {
          userId: userId,
          eventId: this.post.eventId._id
        };
        if (value) {
          this.$store.dispatch("event/registerParticipant", params);
        } else {
          this.$store.dispatch("event/unregisterParticipant", params);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async exportData() {
      this.$store.commit("loader/SET_LOADER", true);
      try {
        const data = [
          {
            sheet: this.$t("page.event.participants"),
            columns: [
              { value: "name", label: this.$t("name") },
              { value: "surname", label: this.$t("surname") },
              { value: "email", label: this.$t("email") },
              {
                value: "registered",
                label: this.$t("page.event.registration")
              }
            ],
            content: this.formatedParticipants.map(p => ({
              ...p,
              registered: p.registered ? this.$t("yes") : this.$t("no")
            }))
          }
        ];

        const settings = {
          fileName: this.post.title,
          extraLength: 3,
          writeMode: "writeFile",
          writeOptions: {}
        };

        await xlsx(data, settings);
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    }
  }
};
</script>

<style></style>

<template>
  <div>
    <b-table
      :fields="fields"
      :items="participants"
      :sort-by.sync="_sortBy"
      :sort-desc.sync="_sortDesc"
      :responsive="true"
      outlined
      hover
      show-empty
      small
      v-bind="$attrs"
    >
      <template #empty>
        <h6 class="text-center text-italic text-uppercase">
          {{ $t("page.condominium.emptyList") }}
        </h6>
      </template>
      <template #head()="data">
        <div
          :class="{ clickable: data.field.sort }"
          @click="data.field.sort && setSort(data.column)"
        >
          {{ $t(data.label) }}
          <svg
            v-if="data.column === _sortBy"
            viewBox="0 0 16 16"
            class="sort-icon"
          >
            <path fill-rule="evenodd" :d="sortIcon"></path>
          </svg>
        </div>
      </template>
      <template #cell(participatedAt)="data">
        <span class="text-capitalize">
          {{ data.value | date("DD/MM/YYYY") }}
        </span>
      </template>
      <template #cell(fullname)="data">
        <div class="d-flex align-items-center">
          <b-avatar class="avatar mr-3" :src="data.item.photo" />
          <div style="max-width: 180px; word-wrap: break-word">
            {{ data.value }}
          </div>
        </div>
      </template>
      <template #cell(email)="data">
        <span>
          {{ data.value }}
        </span>
      </template>
      <template #cell(registered)="data">
        <base-switcher
          label="ds"
          :value="data.value"
          sync
          @change="ev => toggleRegistration(ev, data.item)"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import BaseSwitcher from "../Base/Switcher.vue";
export default {
  components: {
    BaseSwitcher
  },
  props: {
    participants: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => []
    },
    sortBy: {
      type: String,
      default: ""
    },
    sortDesc: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    sortIcon() {
      return this._sortDesc
        ? "M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"
        : "M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z";
    },
    _sortBy: {
      get() {
        return this.sortBy;
      },
      set(value) {
        this.$emit("update:sortBy", value);
      }
    },
    _sortDesc: {
      get() {
        return this.sortDesc;
      },
      set(value) {
        this.$emit("update:sortDesc", value);
      }
    }
  },
  methods: {
    setSort(newSortBy) {
      if (this._sortBy !== newSortBy) {
        this._sortBy = newSortBy;
        this._sortDesc = false;
      } else {
        this._sortDesc = !this._sortDesc;
      }
    },
    toggleRegistration(ev, participant) {
      this.$emit("toggleRegistration", {
        value: ev.value,
        userId: participant.userId
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.list-item-icon {
  width: 20px;
}
.sort-icon {
  width: 20px;
}
.avatar {
  --size: 30px;
  width: var(--size);
  height: var(--size);
}

.underline {
  text-decoration: underline;
}
</style>
